import Decimal from "decimal.js";
import { ReactNode } from "react";

import {
  MoneyMovementSourceType,
  PlaidAccountFragment,
  PlaidAccountStatus,
} from "../generated/graphql";

export type AccountItemBasics = {
  name: string | ReactNode;
  description?: string | ReactNode;
  logo: ReactNode;
};

export enum TransferAccountType {
  DirectIndexing,
  Treasury,
  Cash,
  // External doesn't necessarily mean ACH although it is the default, but for
  // "initiate wire transfer" flow we use CashTransferMethod to override
  External,
  LineOfCredit,
  // Wire only used for in-context transfer
  Wire,
}

export type TransferAccount = AccountItemBasics & {
  id: string;
  balance?: Decimal;
  type: MoneyMovementSourceType;
  withinFrec?: boolean;
  status?: PlaidAccountStatus;
  // TODO: replace this with deposit account and decouple from Plaid
  plaidAccount?: PlaidAccountFragment;
};
