import {
  getConcentratedAdjustedMargin,
  getMarginRequirement,
  getSecurityHoldingsValue,
  RichHolding,
  WithRequired,
  ZERO,
} from "@frec-js/common";
import Decimal from "decimal.js";

import { SecurityTradeStatus } from "../generated/graphql";
import { HoldingData } from "./types";

export type HoldingAssetWithAllocation = HoldingData & {
  allocation: Decimal;
};

export const calculateHoldingReturns = ({
  holdings,
}: {
  holdings: HoldingAssetWithAllocation[];
}) => {
  const holdingsWithReturns = holdings.filter(
    (
      holding
    ): holding is WithRequired<
      HoldingAssetWithAllocation,
      "totalReturnAmount"
    > => holding.totalReturnAmount !== undefined
  );

  const { costBasisAdjustedTotalStockValue, costBasisAdjustedTotalReturn } =
    holdingsWithReturns.reduce(
      (
        { costBasisAdjustedTotalReturn, costBasisAdjustedTotalStockValue },
        holding
      ) => ({
        costBasisAdjustedTotalReturn: costBasisAdjustedTotalReturn.plus(
          holding.totalReturnAmount
        ),
        costBasisAdjustedTotalStockValue: costBasisAdjustedTotalStockValue.plus(
          // Defaulting the cost basis to Zero might be a problem
          holding.totalCostBasisValue ?? ZERO
        ),
      }),
      {
        costBasisAdjustedTotalReturn: ZERO,
        costBasisAdjustedTotalStockValue: ZERO,
      }
    );

  return {
    costBasisAdjustedTotalStockValue,
    costBasisAdjustedTotalReturn,
  };
};

export const calculateHoldingsTotalCostBasis = (holdings: RichHolding[]) => {
  return holdings.reduce((totalCostBasis, holding) => {
    return totalCostBasis.plus(
      (holding.avgCostBasis ?? ZERO).mul(holding.quantity)
    );
  }, ZERO);
};

/**
 *
 * Rounds a number to a specified number of decimal places and handles very
 * small values by showing a threshold
 *
 * Examples:
 *
 * roundWithMinThreshold(0.00002, 2) -> Output: <0.01
 *
 * roundWithMinThreshold(0.005, 2) -> Output: 0.01
 *
 * roundWithMinThreshold(0.1, 2) -> Output: 0.10
 *
 * roundWithMinThreshold(1.567, 2) -> Output: 1.57
 *
 * @param value Decimal value to round
 * @param n Number of decimal places to round to
 * @returns Formatted string or rounded value
 */
export function roundWithMinThreshold(
  value: Decimal,
  n: number,
  isCurrency?: boolean
): string {
  const roundedString = value.toFixed(n);
  const roundedValue = Number(roundedString);
  if (roundedValue === 0) {
    const smallestNonZero = Math.pow(10, -n);
    return isCurrency
      ? `<$${smallestNonZero.toFixed(n)}`
      : `<${smallestNonZero.toFixed(n)}`;
  }
  return isCurrency ? `$${roundedString}` : roundedString;
}

/**
 * Helper which constructs the holdings data from a given set of holdings (direct index or self managed)
 */
export const buildHoldingsData = (
  holdings: RichHolding[],
  {
    filter,
    onlyLiquidHoldings,
  }: {
    onlyLiquidHoldings?: boolean;
    filter?: (data: HoldingData) => boolean;
  } = {}
): {
  blendedLTV: Decimal;
  holdingsData: HoldingData[];
  totalStockValue: Decimal;
} => {
  const totalStockValue = getSecurityHoldingsValue(holdings);

  // TODO: consider whole shares as liquid as well?
  const basicHoldings = holdings
    .filter(
      (h) =>
        !onlyLiquidHoldings || h.sellStatus === SecurityTradeStatus.Fractional
    )
    .map((h) => {
      const value = h.price.mul(h.quantity);
      const todayChangePercent = h.previousClose
        ? h.change?.mul(100)?.div(h.previousClose)
        : undefined;
      const totalReturnPercent = h.avgCostBasis?.gt(0)
        ? h.price.sub(h.avgCostBasis).mul(100).div(h.avgCostBasis)
        : undefined;
      const totalCostBasisValue = h.avgCostBasis?.gt(0)
        ? h.avgCostBasis.mul(h.quantity)
        : undefined;
      const totalReturnAmount = totalCostBasisValue
        ? value.minus(totalCostBasisValue)
        : undefined;
      const LTV = new Decimal(1).minus(
        getConcentratedAdjustedMargin(h, totalStockValue) // totalStockValue is the wrong total here; we should include both DI and self-managed holdings
      );

      return {
        securityId: h.securityId,
        symbol: h.symbol,
        name: h.name,
        value,
        quantity: h.quantity,
        pricePerShare: h.price,
        price: h.price,
        open: h.open,
        previousClose: h.previousClose,
        costBasis: h.avgCostBasis,
        todayChangePercent,
        todayChangeAmount: h.quantity.mul(h.change || 0),
        totalReturnPercent,
        totalReturnAmount,
        totalCostBasisValue,
        marginRequirement: h.marginRequirement,
        LTV,
        type: h.type,
        subType: h.subType,
        mmfMetadata: h.mmfMetadata,
        dividendYtd: h.dividendYtd,
      } as HoldingData;
    })
    .filter(filter ?? (() => true));

  // Allocation must be based on the filtered total
  const filteredHVal = filter
    ? getSecurityHoldingsValue(basicHoldings)
    : totalStockValue;
  const holdingsData = basicHoldings.map((h) => ({
    ...h,
    allocation: h.value.dividedBy(filteredHVal),
  }));

  const blendedLTV = new Decimal(1).minus(getMarginRequirement(holdings));

  return {
    blendedLTV,
    holdingsData,
    totalStockValue,
  };
};
